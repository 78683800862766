// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-templates-events-events-tsx": () => import("./../../../src/templates/events/events.tsx" /* webpackChunkName: "component---src-templates-events-events-tsx" */),
  "component---src-templates-main-main-tsx": () => import("./../../../src/templates/main/main.tsx" /* webpackChunkName: "component---src-templates-main-main-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */)
}

